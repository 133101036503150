import { showDateField, showLastNameField } from '@/libs/ChallengeHelper';
import { logger } from '@/libs/logger';
import { CheckInOut, LanguageMenu } from '@components';
import AppConfig from '@config';
import {
  isVerificationFinished,
  isVerificationFlowEnabled,
  trackEvent,
} from '@libs';
import useLocalization, { SupportedLocale } from '@localizations';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import {
  dispatchGetCompany,
  dispatchGetGuest,
  dispatchLocale,
  processError,
  useAppContext,
  useAppStore,
} from '@store';
import { LoginChallengeLogo } from '@svgs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { HelperText, TextInput, useTheme } from 'react-native-paper';
import { CalendarDates } from 'react-native-paper-dates/lib/typescript/Date/Calendar';
import { CheckInOut as CheckInOutType } from '../../types/reservations';
import {
  ButtonView,
  ButtonViewHandle,
  ContentView,
  HeaderView,
} from './Common';
import { shouldNavigateToAuth } from './LoginCognito/utils';
import { LoginStyles } from './Styles';

type FSPayload = { lastName?: string; checkIn?: string; checkOut?: string };

// @@ Action view
const ActionView = () => {
  const submitButtonRef = useRef<ButtonViewHandle>(null);
  const [lastName, setLastName] = useState('');
  const [checkInOut, setCheckInOut] = useState<CheckInOutType | undefined>(
    undefined,
  );
  const [missingInputError, setMissingInputError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAPIError, setShowAPIError] = useState(false);
  const { t } = useLocalization();
  const { company, reservation } = useAppStore();
  const navigation = useNavigation();

  const onPress = useCallback(async () => {
    let challengeType = 'Last Name';
    let fsPayload: FSPayload = { lastName };

    if (lastName.length > 0 && checkInOut) {
      challengeType = 'Both';
      fsPayload = {
        lastName,
        checkIn: checkInOut?.checkIn?.toDateString(),
        checkOut: checkInOut?.checkOut?.toDateString(),
      };
    } else if (showDateField(company) && checkInOut && lastName.length === 0) {
      challengeType = 'Dates';
      fsPayload = {
        checkIn: checkInOut?.checkIn?.toDateString(),
        checkOut: checkInOut?.checkOut?.toDateString(),
      };
    }
    if (company === undefined) {
      throw new Error('Company is undefined');
    } else if (
      (showLastNameField(company) && lastName.length === 0) ||
      (showDateField(company) && !checkInOut)
    ) {
      setMissingInputError(true);
      return false;
    } else {
      setMissingInputError(false);
      setShowAPIError(false);
      setLoading(true);

      try {
        trackEvent('Challenge', `${challengeType}: Submitted`, fsPayload);
        await dispatchGetGuest(company, lastName.trim(), checkInOut);
        return true;
      } catch (e) {
        setShowAPIError(true);
        return false;
      } finally {
        setLoading(false);
      }
    }
  }, [company, lastName, checkInOut]);

  const setDates = (dates: CalendarDates) => {
    if (dates) {
      setCheckInOut({
        checkIn: dates[0],
        checkOut: dates[1],
      });
    }
  };

  useEffect(() => {
    if (!reservation || !isVerificationFlowEnabled(reservation)) {
      return;
    }

    if (!isVerificationFinished(reservation)) {
      navigation.navigate('LoginVerificationFlow');
    }
  }, [reservation, navigation, company]);

  return (
    <View
      style={{
        width: '100%',
        paddingHorizontal: AppConfig.Styles.getAdjustedSize(24),
        paddingBottom: AppConfig.Styles.getAdjustedSize(24),
      }}>
      {showDateField(company) && (
        <View key="checkInOutChallenge">
          <CheckInOut setDates={setDates} />
          <HelperText type="error" visible={missingInputError}>
            {t('validation_required')}
          </HelperText>
        </View>
      )}
      {showLastNameField(company) && (
        <View key="lastNameChallenge" style={[AppConfig.Styles.container]}>
          <TextInput
            style={{
              height: AppConfig.Styles.inputHeight,
            }}
            testID="challenge-input"
            mode="outlined"
            label={t('signup_last_name')}
            value={lastName}
            onSubmitEditing={() => submitButtonRef.current?.submit()}
            onChangeText={text => setLastName(text)}
          />
          <HelperText type="error" visible={missingInputError}>
            {t('validation_required')}
          </HelperText>
        </View>
      )}
      {showAPIError && (
        <HelperText type="error">{t('step_one_error_message')}</HelperText>
      )}
      <ButtonView
        ref={submitButtonRef}
        onSubmit={onPress}
        company={company}
        loading={loading}
        currentPage="Challenge"
        backButton={false}
      />
    </View>
  );
};

// @@ Default Component
export const Challenge = () => {
  const { locale, setLocale } = useAppContext();
  const { t } = useLocalization();
  const themeProvider = useTheme();
  const { company, theme } = useAppStore();
  const navigation = useNavigation();
  const challengeSubtitle = showDateField(company)
    ? 'step_one_subtitle_dates'
    : 'step_one_subtitle_lastname';

  const handleSelectedLanguage = async (selected: SupportedLocale) => {
    if (selected === locale) {
      return;
    }

    try {
      const storageKey = company?.id ? `locale-${company.id}` : 'locale';
      await AsyncStorage.setItem(storageKey, selected);
      setLocale(selected);
      await dispatchLocale(selected, company);
      if (company) {
        const configId = `${company?.id}_${company?.reservation}`;
        await dispatchGetCompany(configId);
      }
    } catch (error) {
      const message = processError(error);
      logger.error(message);

      // TODO: show feedback to user
    }
  };

  useEffect(() => {
    if (shouldNavigateToAuth(company)) {
      navigation.navigate('Auth');
    }
  }, [company, navigation]);

  return (
    <View style={[AppConfig.Styles.container, AppConfig.Styles.center]}>
      <View style={[AppConfig.Styles.container, LoginStyles.container]}>
        <View style={[LoginStyles.contentContainer]}>
          {theme && <HeaderView theme={theme} />}
          <ContentView
            title={t('step_one_title')}
            description={t(challengeSubtitle)}>
            {theme?.enabled && theme?.welcomeImagesEnabled && (
              <LoginChallengeLogo
                style={{ color: themeProvider.colors.primary }}
                width={AppConfig.Styles.getAdjustedSize(230)}
              />
            )}
          </ContentView>
        </View>
        <ActionView />
      </View>
      <LanguageMenu selected={locale} onClose={handleSelectedLanguage} />
    </View>
  );
};

export default Challenge;
